import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/city-heights-culture-community");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_City Heights Unveiled_ A Blend of Culture and Community.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Looking for a San Diego neighborhood teeming with life—one that's rich in both
                diversity and opportunity? Look no further than City Heights!
              </CommonParagraph>
              <CommonParagraph>
                Whether you're a first-time buyer ready to find your perfect place or an investor
                looking to build up your real estate portfolio, City Heights has an undeniable
                appeal that makes it stand out. Here, we'll introduce you to all this neighborhood
                offers and why it should be on your list of areas to explore.
              </CommonParagraph>
              <Heading type="h2">The Cultural Draw of City Heights</Heading>
              <CommonParagraph>
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/city-heights-homes-for-sale"
                  text="City Heights"
                />{" "}
                in San Diego is known for its affordable homes and cultural diversity, making it a
                great option for those looking to put down roots. From its origins as a trolley car
                suburb in the early 20th century to its transformation into a bustling urban hub,
                City Heights has managed to evolve as the times change and do so without losing its
                vibrant, welcoming spirit.
              </CommonParagraph>
              <CommonParagraph>
                City Heights offers a rare blend of affordability and quality of life. Those looking
                to buy a home can find options ranging from charming bungalows to vast townhomes,
                while those interested in selling can benefit from the area's growing appeal and
                demand.
              </CommonParagraph>
              <Heading type="h3">Rich in Tradition</Heading>
              <CommonParagraph>
                City Heights is one of the most colorful and dynamic neighborhoods in all of San
                Diego. People from around the world have moved into the area, bringing with them
                their traditions, languages, and cuisine. It's easy to see the mix of these cultural
                influences throughout the region, where different communities are celebrated.
              </CommonParagraph>
              <Heading type="h3">Numerous Events and Festivals</Heading>
              <CommonParagraph>
                Speaking of celebrating, City Heights comes alive with events and festivals
                throughout the year. From the Annual City Heights Street Food Fest to the events
                hosted by the Mid-City Community Advocacy Network (CAN), the inclusive spirit of
                this neighborhood offers many opportunities for residents to come together. If
                community and inclusion are what you're after,{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="City Heights is the place for you"
                />
                .
              </CommonParagraph>
              <Heading type="h3">Delicious Cuisine</Heading>
              <CommonParagraph>
                With all the different cultural influences, it's easy to see why City Heights is a
                foodie's paradise. Whether you're craving pho from a local Vietnamese restaurant,
                want to try Ethiopian injera, or are in the mood for delicious tacos from a
                family-run taqueria, you'll find it here. Enjoy cuisine from nearly every corner of
                the world right in your neighborhood.
              </CommonParagraph>
              <Heading type="h2">Community Spirit</Heading>
              <CommonParagraph>
                Beyond unique cultures, what lies at the heart of City Heights? How the residents
                from all these different cultures come together.
              </CommonParagraph>
              <Heading type="h3">Neighborhood Associations</Heading>
              <CommonParagraph>
                Several community-driven organizations in the area, such as the City Heights Town
                Council, foster collaboration and neighborhood improvements. What's the main goal of
                these neighborhood associations? Keep the city a welcoming place for current and
                future residents.
              </CommonParagraph>
              <Heading type="h3">Family-Friendly</Heading>
              <CommonParagraph>
                With its variety of schools, parks, and community centers, City Heights is one of
                the most family-friendly neighborhoods in San Diego. If you're looking for
                opportunities for education, recreation, and connection, you'll find them here! For
                those with kids, there are several children's programs to explore.
              </CommonParagraph>
              <Heading type="h2">Let The Selby Team Help You Find Your City Heights Home</Heading>
              <CommonParagraph>
                City Heights is more than just a neighborhood; it's a community that blends culture,
                affordability, and opportunity. And The Selby Team is more than just a group of
                realtors; we're local real estate experts dedicated to helping you start your next
                chapter. <ContactSlideoutLink text="Get started today" />.
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
